import { useEffect } from 'react';

const TelegramWebAppComponent = () => {
  useEffect(() => {
    // Function to load the Telegram SDK
    const loadTelegramSDK = () => {
      // Check if the script already exists
      if (!document.querySelector('script[src="https://telegram.org/js/telegram-web-app.js"]')) {
        const script = document.createElement('script');
        script.src = 'https://telegram.org/js/telegram-web-app.js';
        script.async = true;
        script.onload = () => {
          console.log('Telegram Web App SDK script loaded');
          initializeTelegramWebApp();
        };
        script.onerror = () => {
          console.error('Failed to load Telegram Web App SDK script');
        };
        document.body.appendChild(script);
      } else {
        // If the script already exists, initialize directly
        console.log('Telegram Web App SDK script already exists');
        initializeTelegramWebApp();
      }
    };

    // Function to initialize the Telegram Web App with retries
    const initializeTelegramWebApp = (retries = 10, delay = 100) => {
      if (window.Telegram && window.Telegram.WebApp) {
        console.log('Initializing Telegram Web App');
        window.Telegram.WebApp.ready();

        // Example of using TelegramGameProxy safely
        if (window.TelegramGameProxy && typeof window.TelegramGameProxy.receiveEvent === 'function') {
          window.TelegramGameProxy.receiveEvent('someEvent');
        } else {
          console.error('TelegramGameProxy or receiveEvent is not defined');
        }
      } else if (retries > 0) {
        console.log(`Telegram Web App SDK not available, retrying in ${delay}ms...`);
        setTimeout(() => initializeTelegramWebApp(retries - 1, delay), delay);
      } else {
        console.error('Telegram Web App SDK is not available after retries');
      }
    };

    loadTelegramSDK();
  }, []);

  return null; // No UI needed for this component
};

export default TelegramWebAppComponent;
