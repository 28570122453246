import React, { createContext, useState, useEffect, useCallback } from 'react';

const TimerContext = createContext();

const TimerProvider = ({ children }) => {
  const maxTime = 4 * 60 * 60; // 4 hours in seconds
  const initialTimeLeft = 1 * 60 * 60; // 1 hour in seconds
  const initialPPH = 1000; // Profit per hour
  const initialUserScore = 2116; // Initial user score

  const [timeElapsed, setTimeElapsed] = useState(initialTimeLeft);
  const [userScore, setUserScore] = useState(initialUserScore);
  const [isScaling, setIsScaling] = useState(false);
  const [claimCounter, setClaimCounter] = useState(0);
  const [showClaim, setShowClaim] = useState(false);

  useEffect(() => {
    if (timeElapsed > 0) {
      const timer = setInterval(() => {
        setTimeElapsed((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [timeElapsed]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleClaimClick = useCallback(() => {
    const elapsedHours = (maxTime - timeElapsed) / 3600; // Calculate elapsed time in hours
    const scoreToAdd = elapsedHours * initialPPH; // Calculate score to add based on PPH and elapsed hours
  
    let currentScore = userScore;
    const increment = Math.ceil(scoreToAdd / 100); // Increment to smoothly animate the score addition
  
    setIsScaling(true); // Start scaling animation
    setShowClaim(true); // Show the CoinAnimation component
  
    const animationInterval = setInterval(() => {
      if (currentScore < userScore + scoreToAdd) {
        currentScore += increment;
        setUserScore(currentScore);
      } else {
        setUserScore(userScore + scoreToAdd);
        clearInterval(animationInterval);
        setIsScaling(false); // Stop scaling animation
      }
    }, 20); // Adjust the interval for smoother animation
  
    setTimeElapsed(maxTime); // Reset the timer
    setClaimCounter((prev) => prev + 1); // Increment the counter to force re-render
  }, [timeElapsed, initialPPH, userScore, maxTime]);

  return (
    <TimerContext.Provider
      value={{
        maxTime,
        timeElapsed,
        userScore,
        isScaling,
        setIsScaling,
        claimCounter,
        handleClaimClick,
        showClaim,
      }}
    >
      {children}
    </TimerContext.Provider>
  );
};

export { TimerContext, TimerProvider };
