import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Auth from "../components/Auth"; // Import the Auth component
import "../App.css";

const WelcomePage = ({ onProgressComplete }) => {
  const [progress, setProgress] = useState(0);
  const [authStatus, setAuthStatus] = useState(null); // Tracks the status of authentication or registration

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const tg_id = "31343083";
    const tg_username = "jahanjavid";

    if (!tg_id) {
      toast.error("Missing credentials. Please restart the process.");
    }
  }, []);

  const handleAuthComplete = (status) => {
    setAuthStatus(status);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress >= 100) {
          clearInterval(interval);
          return 100;
        }
        return prevProgress + 2;
      });
    }, 100);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (progress === 100) {
      if (authStatus === "success") {
        onProgressComplete(); // Proceed to the next step if authentication/registration is successful
      } else if (authStatus === "failed") {
        // Stay on the welcome page and show an error
        toast.error("Please try again later.");
      }
    }
  }, [progress, authStatus, onProgressComplete]);

  return (
    <div className="flex items-center justify-center min-h-screen">
      <div className="text-center max-w-md w-full">
        <Auth
          tg_id="31343083"
          tg_username="jahanjavid"
          onAuthComplete={handleAuthComplete}
        />
        <div className="flex flex-col h-full">
          <div className="text-start mx-4 md:mx-10 mb-5">
            <h1 className="text-3xl md:text-5xl font-bold mb-2 bg-gradient-to-r from-cyan-500 to-blue-500 inline-block text-transparent bg-clip-text">
              Griffin coin
            </h1>
            <div className="mt-2 md:mt-5">
              <p className="bg-gradient-to-r from-cyan-500 to-blue-500 inline-block text-transparent bg-clip-text text-xs md:text-[10px]">
                First 3 level referral game will be launched{" "}
                <span className="font-bold">on Ton</span>
              </p>
              <h3 className="bg-gradient-to-r from-cyan-500 to-blue-500 text-transparent bg-clip-text text-xs md:text-[14px]">
                This is just a beginning
              </h3>
              <p className="text-xs md:text-sm">How does that sound?</p>
            </div>
          </div>
          <div className="bottom-0 w-full my-4">
            <div className="text-center">
              <div className="flex justify-center items-center space-x-4 mb-4 py-3 md:py-6 max-w-full md:max-w-4xl rounded-2xl mx-4 md:mx-8 overflow-hidden shadow-lg bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg">
                <img
                  className="inline-block w-16 md:w-20"
                  src={process.env.PUBLIC_URL + "/assets/img/ton-coin.webp"}
                  alt="griffin"
                />
                <img
                  className="inline-block w-16 md:w-20"
                  src={process.env.PUBLIC_URL + "/assets/img/griffin-coin.webp"}
                  alt="ton"
                />
              </div>
              <div className="w-48 md:w-60 mx-auto">
                <img
                  src={
                    process.env.PUBLIC_URL + "/assets/img/welcome-griffin.webp"
                  }
                  alt="App Icon"
                  className="mx-auto mb-0 w-full"
                />
              </div>
              <div className="progress-bar-container mt-2 md:mt-4 w-full md:w-1/2 lg:w-1/3 mx-auto">
                <div
                  className="progress-bar h-2 bg-gradient-to-r from-cyan-500 to-blue-500"
                  style={{ width: `${progress}%` }}
                ></div>
              </div>
              <p className="mt-3 mb-2 text-xs">Loading...</p>
              <div className="mt-4">
                <span className="font-bold text-xs">Stay tuned</span>
                <p className="font-bold text-xs">
                  More info in official channels
                </p>
                <div className="flex justify-center items-center space-x-2 md:space-x-4 mt-2">
                  <img
                    className="inline-block w-8"
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/img/socials/telegram-50.webp"
                    }
                    alt="telegram"
                  />
                  <img
                    className="inline-block w-8"
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/img/socials/youtube-50.webp"
                    }
                    alt="youtube"
                  />
                  <img
                    className="inline-block w-8"
                    src={
                      process.env.PUBLIC_URL + "/assets/img/socials/x-50.webp"
                    }
                    alt="twitterx"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WelcomePage;
