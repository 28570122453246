import React, { useState, useEffect } from 'react';
import './App.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './index.css';
import WelcomePage from './components/WelcomePage';
import BottomMenu from './components/BottomMenu';
import TelegramWebAppComponent from './components/TelegramWebAppComponent'; 
import Home from './pages/Home';
import Tasks from './pages/Tasks';
import InviteFriends from './pages/InviteFriends';
import Airdrop from './pages/Airdrop';
import Boost from './pages/Boost';
import Activity from './pages/Activity';
import { TimerProvider } from './contexts/TimerContext';

const App = () => {
  const [activeSection, setActiveSection] = useState('home');
  const [showWelcomePage, setShowWelcomePage] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false); // Track authentication status
  const [showClaim, setShowClaim] = useState(false);

  useEffect(() => {
    if (isAuthenticated) {
      const timer = setTimeout(() => setShowWelcomePage(false), 5000);
      return () => clearTimeout(timer);
    }
  }, [isAuthenticated]);

  const handleProgressComplete = () => {
    setShowWelcomePage(false);
    setIsAuthenticated(true); // Assume the user is authenticated after welcome screen
  };

  const handleNavigation = (section) => {
    if (section !== 'home') {
      setShowClaim(false); // Reset showClaim when navigating away from home
    }
    setActiveSection(section);
  };

  return (
    <TimerProvider>
      <div className="bg-gray-100 flex flex-col transition-background duration-300 app-body">
        <TelegramWebAppComponent />
        <ToastContainer
          position="top-center"
          toastClassName={() => 'text-xs font-white font-med bg-black bg-opacity-5 backdrop-filter backdrop-blur-lg rounded-full shadow-lg p-2 m-2'}
          autoClose={5000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          closeButton={false}
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
        {showWelcomePage ? (
          <WelcomePage onProgressComplete={handleProgressComplete} />
        ) : (
          isAuthenticated && (
            <div className="flex-1 flex flex-col">
              <div className="flex-1 p-4 overflow-auto">
                {activeSection === 'home' && <Home handleNavigation={handleNavigation} showClaim={showClaim} setShowClaim={setShowClaim} />}
                {activeSection === 'tasks' && <Tasks />}
                {activeSection === 'invite-friends' && <InviteFriends />}
                {activeSection === 'airdrop' && <Airdrop />}
                {activeSection === 'boost' && <Boost />}
                {activeSection === 'activity' && <Activity />}
              </div>
              <BottomMenu handleNavigation={handleNavigation} activeSection={activeSection} />
            </div>
          )
        )}
      </div>
    </TimerProvider>
  );
};

export default App;
