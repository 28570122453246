import React from "react";

const Formatter = ({ number, formatType }) => {
  const formatNumber = (num) => {
    if (num >= 1000000000) {
      return parseFloat((num / 1000000000).toFixed(2)) + "B";
    } else if (num >= 1000000) {
      return parseFloat((num / 1000000).toFixed(2)) + "M";
    } else if (num >= 1000) {
      return parseFloat((num / 1000).toFixed(2)) + "K";
    } else {
      return num.toString();
    }
  };

  const formatWithSeparator = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  // Handle undefined or null numbers
  if (number === undefined || number === null) {
    return <span>N/A</span>; // Return a placeholder if the number is undefined or null
  }

  return (
    <span>
      {formatType === "number"
        ? formatNumber(number)
        : formatWithSeparator(number)}
    </span>
  );
};

export default Formatter;
