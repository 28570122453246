import React, { useState } from "react";
import Ranking from "../components/activity/Ranking";
import Slider from "../components/activity/Slider";

const Activity = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  return (
    <div className="max-w-md mx-auto px-4">
      <Slider currentIndex={currentIndex} setCurrentIndex={setCurrentIndex} />
      <Ranking currentIndex={currentIndex} />
    </div>
  );
};

export default Activity;
