import React from "react";
import Tabs from "../components/Tabs";

const Tasks = () => {
  return (
    <div className="max-w-md mx-auto p-4">
      <div className="text-center mb-4">
        <h2 className="text-2xl font-bold mb-2">Available Tasks</h2>
        <p>Complete any task and claim your reward fren!</p>
      </div>
      <Tabs />
    </div>
  );
};

export default Tasks;
