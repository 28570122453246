import React, { useState, useEffect } from "react";
import feather from "feather-icons";
import { toast } from "react-toastify";
import { motion } from "framer-motion";
import DirectRefModal from '../components/modals/DirectRefModal';

import "react-toastify/dist/ReactToastify.css";

const InviteFriends = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    feather.replace(); // Initialize feather icons
  }, []);

  const handleCopyClick = () => {
    toast.success("Referral link copied to clipboard!", {
      position: "top-center",
      closeButton: false,
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      theme: "dark",
    });
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 5 },
    visible: { opacity: 1, y: 0 },
  };

  const buttonVariants = {
    tap: {
      scale: 0.9,
      transition: { duration: 0.1 },
    },
  };

  return (
    <div className="max-w-md mx-auto p-4 overflow-y-scroll" style={{ height: "calc(100vh - 75px)" }}>
      <motion.div className="text-center mb-6" initial="hidden" animate="visible" variants={itemVariants} transition={{ delay: 0.1 }}>
        <h2 className="text-2xl font-bold mb-4">Invite Friends!</h2>
        <p>Invite your friends and both receive bonuses.</p>
      </motion.div>

      {/* Example of inviting a friend */}
      <motion.div className="flex gap-2 mb-4 p-2 bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg rounded-lg shadow-lg" initial="hidden" animate="visible" variants={itemVariants} transition={{ delay: 0.2 }}>
        <img src="./assets/img/gift-sm.webp" alt="Invite 1" className="w-20 h-20 object-cover" />
        <div className="ml-4 flex flex-col justify-center">
          <h3 className="text-xs font-bold mb-2">Invite a friend</h3>
          <p className="text-xs flex items-center">
            <img src="./assets/img/coin-simple.webp" alt="Griffin" className="h-4 mr-1 inline-block" />
            <span className="mr-1 font-bold bg-gradient-to-r from-orange-400 to-yellow-600 inline-block text-transparent bg-clip-text">
              +5,000
            </span>{" "}
            for you and your friend
          </p>
        </div>
      </motion.div>

      {/* Example of inviting a friend with Telegram Premium */}
      <motion.div className="flex gap-4 mb-4 p-2 bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg rounded-lg shadow-lg items-center" initial="hidden" animate="visible" variants={itemVariants} transition={{ delay: 0.3 }}>
        <img src="./assets/img/gift.webp" alt="Invite 2" className="w-20 h-20 object-cover" />
        <div className="flex flex-col justify-center">
          <h3 className="font-bold mb-1 text-xs">Invite a friend with Telegram Premium</h3>
          <p className="text-xs flex items-center">
            <img src="./assets/img/coin-simple.webp" alt="Griffin" className="h-4 mr-1 inline-block" />
            <span className="mr-1 font-bold bg-gradient-to-r from-orange-400 to-yellow-600 inline-block text-transparent bg-clip-text">
              +25,000
            </span>{" "}
            for you and your friend
          </p>
        </div>
      </motion.div>

      {/* Button to open modal */}
      <motion.div className="text-start mt-12 mb-4 flex items-center justify-between" initial="hidden" animate="visible" variants={itemVariants} transition={{ delay: 0.4 }}>
        <p className="mr-2">List of your referrals</p>
        <button className="flex items-center text-xs text-right bg-transparent border-none cursor-pointer opacity-70" style={{ outline: "none" }} onClick={openModal}>
          <div className="inline-block relative mr-2" style={{ borderRadius: "50%", background: "linear-gradient(to right, green, lime)", padding: "2px", width: "24px", height: "24px" }}>
            <div className="flex items-center justify-center rounded-full" style={{ width: "100%", height: "100%" }}>
              <i data-feather="eye" style={{ width: "16px", height: "16px" }}></i>
            </div>
          </div>
          Direct REF
        </button>
      </motion.div>

      <div className="grid grid-cols-1 gap-4 mb-6">
        <motion.div
          className="flex items-center p-4 bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg rounded-lg shadow-lg rounded-lg"
          initial="hidden"
          animate="visible"
          variants={itemVariants}
          transition={{ delay: 0.5 }}
        >
          <div className="flex-1">
            <h3 className="text-lg font-bold mb-2">Level 1</h3>
            <p className="text-xs flex items-center">
              <i
                data-feather="user-plus"
                className="mr-1 h-5"
                size={2}
                color="#9333ea"
              ></i>
              <span className="font-bold bg-gradient-to-r from-blue-400 to-purple-600 inline-block text-transparent bg-clip-text px-2 py-1 rounded">
                35 Person
              </span>
            </p>
            <p className="text-xs flex items-center">
              <img
                src="./assets/img/coin-simple.webp"
                alt="Griffin"
                className="h-4 mr-3 inline-block"
              />
              <span className="font-bold bg-gradient-to-r from-orange-400 to-yellow-600 inline-block text-transparent bg-clip-text px-2 py-1 rounded">
                25,000
              </span>
            </p>
          </div>
          <img
            src="./assets/img/referral-badges/level-1.webp"
            alt="Badge Level 2"
            className="w-12 h-12"
          />
        </motion.div>

        <motion.div
          className="flex items-center p-4 bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg rounded-lg shadow-lg"
          initial="hidden"
          animate="visible"
          variants={itemVariants}
          transition={{ delay: 0.6 }}
        >
          <div className="flex-1">
            <h3 className="text-lg font-bold mb-2">Level 2</h3>
            <p className="text-xs flex items-center">
              <i
                data-feather="user-plus"
                className="mr-1 h-5"
                size={2}
                color="#9333ea"
              ></i>
              <span className="font-bold bg-gradient-to-r from-blue-400 to-purple-600 inline-block text-transparent bg-clip-text px-2 py-1 rounded">
                35 Person
              </span>
            </p>
            <p className="text-xs flex items-center">
              <img
                src="./assets/img/coin-simple.webp"
                alt="Griffin"
                className="h-4 mr-3 inline-block"
              />
              <span className="font-bold bg-gradient-to-r from-orange-400 to-yellow-600 inline-block text-transparent bg-clip-text px-2 py-1 rounded">
                25,000
              </span>
            </p>
          </div>
          <img
            src="./assets/img/referral-badges/level-2.webp"
            alt="Badge Level 2"
            className="w-12 h-12"
          />
        </motion.div>

        <motion.div
          className="flex items-center p-4 bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg rounded-lg shadow-lg"
          initial="hidden"
          animate="visible"
          variants={itemVariants}
          transition={{ delay: 0.7 }}
        >
          <div className="flex-1">
            <h3 className="text-lg font-bold mb-2">Level 3</h3>
            <p className="text-xs flex items-center">
              <i
                data-feather="user-plus"
                className="mr-1 h-5"
                size={2}
                color="#9333ea"
              ></i>
              <span className="font-bold bg-gradient-to-r from-blue-400 to-purple-600 inline-block text-transparent bg-clip-text px-2 py-1 rounded">
                35 Person
              </span>
            </p>
            <p className="text-xs flex items-center">
              <img
                src="./assets/img/coin-simple.webp"
                alt="Griffin"
                className="h-4 mr-3 inline-block"
              />
              <span className="font-bold bg-gradient-to-r from-orange-400 to-yellow-600 inline-block text-transparent bg-clip-text px-2 py-1 rounded">
                25,000
              </span>
            </p>
          </div>
          <img
            src="./assets/img/referral-badges/level-3.webp"
            alt="Badge Level 2"
            className="w-12 h-12"
          />
        </motion.div>
      </div>

      {/* Buttons for inviting friends and copying link */}
      <motion.div className="flex space-x-2 mt-4">
        <motion.button
          className="w-full py-3 bg-gradient-to-r from-purple-500 to-blue-500 text-white font-bold rounded-s-full shadow-lg flex items-center justify-center relative overflow-hidden"
          variants={buttonVariants}
          whileHover="hover"
          whileTap="tap"
        >
          <span className="absolute inset-0 rounded-s-full border-2 border-transparent bg-gradient-to-r from-purple-500 via-blue-500 to-purple-500 animate-moving-light"></span>
          <span className="relative flex items-center">
            <i data-feather="user-plus" className="mr-2"></i>Invite Friend
          </span>
        </motion.button>
        <motion.button
          onClick={handleCopyClick}
          className="py-3 px-4 bg-gradient-to-r from-purple-500 to-blue-500 text-white font-bold rounded-e-full shadow-lg flex items-center justify-center relative overflow-hidden"
          variants={buttonVariants}
          whileHover="hover"
          whileTap="tap"
        >
          <span className="absolute inset-0 rounded-e-full border-2 border-transparent bg-gradient-to-r from-purple-500 via-blue-500 to-purple-500 animate-moving-light"></span>
          <span className="relative flex items-center">
            <i data-feather="copy"></i>
          </span>
        </motion.button>
      </motion.div>

      {/* Direct Ref Modal */}
      <DirectRefModal isModalOpen={isModalOpen} closeModal={closeModal} />
    </div>
  );
};

export default InviteFriends;
