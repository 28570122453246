import React from "react";

const Airdrop = () => {
  return (
    <div className="flex items-center justify-center min-h-screen md:p-0 -mt-20">
      <div className="text-center max-w-xs w-full md:max-w-md lg:max-w-lg">
        <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold mb-4">Airdrop</h2>
        <p className="mb-4 text-sm md:text-base lg:text-lg">Participate in the airdrop here.</p>
        <div className="w-pulse mx-auto mb-2">
          <div className="relative my-12 flex justify-center">
            <div className="img-wrapper relative">
              <img
                src="./assets/img/wallet.webp"
                alt="Wallet"
                className="h-40 md:h-52 lg:h-60 object-cover"
              />
              <div className="absolute inset-0 flex items-center justify-center">
                <div className="w-ring"></div>
                <div className="w-ring"></div>
                <div className="w-ring"></div>
              </div>
            </div>
          </div>
        </div>
        <h1 className="text-xl md:text-2xl lg:text-3xl font-bold mb-6">Coming soon...</h1>
        <button
          className="w-full py-3 bg-gradient-to-r from-orange-400 to-yellow-500 opacity-30 text-white font-bold rounded-full shadow-lg flex items-center justify-center"
          disabled
        >
          Connect to wallet
        </button>
      </div>
    </div>
  );
};

export default Airdrop;
