import React from "react";
import Plans from "../components/Plans";

const Boost = () => {
  return (
    <div className="max-w-md mx-auto">
      <div className="text-center mb-4">
        <h2 className="text-2xl font-bold mb-2">Boosters</h2>
        <p>Boost your rewards here.</p>
      </div>
      <Plans />
    </div>
  );
};

export default Boost;

