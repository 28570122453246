import React from "react";
import { motion } from "framer-motion";
import ProgressBar from "./ProgressBar";
import Formatter from "../Formatter";

const Ranking = ({ currentIndex }) => {
  const rankLists = [
    [
      { id: 1, image: "avatar/Dreamteam_1.webp", name: "User 1", griffin: 1009996050, ranking: 1 },
      { id: 2, image: "avatar/Dreamteam_2.webp", name: "User 2", griffin: 900000000, ranking: 2 },
      { id: 3, image: "avatar/Dreamteam_3.webp", name: "User 3", griffin: 850000000, ranking: 3 },
      { id: 10, image: "avatar/Dreamteam_1.webp", name: "User 1", griffin: 100, ranking: 1 },
      { id: 11, image: "avatar/Dreamteam_1.webp", name: "User 1", griffin: 100, ranking: 1 },
      { id: 22, image: "avatar/Dreamteam_2.webp", name: "User 2", griffin: 90, ranking: 2 },
      { id: 33, image: "avatar/Dreamteam_3.webp", name: "User 3", griffin: 80, ranking: 3 },
      { id: 15, image: "avatar/Dreamteam_1.webp", name: "User 1", griffin: 100, ranking: 1 },
    ],
    [
      { id: 4, image: "avatar/Dreamteam_4.webp", name: "User 1", griffin: 100, ranking: 1 },
      { id: 5, image: "avatar/Dreamteam_5.webp", name: "User 2", griffin: 90, ranking: 2 },
      { id: 6, image: "avatar/Dreamteam_6.webp", name: "User 3", griffin: 80, ranking: 3 },
    ],
    [
      { id: 7, image: "avatar/Dreamteam_1.webp", name: "User 1", griffin: 100, ranking: 1 },
      { id: 8, image: "avatar/Dreamteam_3.webp", name: "User 2", griffin: 90, ranking: 2 },
      { id: 9, image: "avatar/Dreamteam_5.webp", name: "User 3", griffin: 80, ranking: 3 },
    ],
  ];

  const users = rankLists[currentIndex];

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <div className="max-w-md mx-auto">
      
        <ProgressBar label="2890 / 75K" width={70} delay={0} />
      
      <h1 className="text-center mt-6 font-bold bg-gradient-to-r from-red-600 to-purple-500 text-transparent bg-clip-text">Global Ranking</h1>
      <div
        className="overflow-y-scroll"
        style={{ maxHeight: "calc(100vh - 350px)" }}
      >
        <div
          className="max-w-md w-full mx-auto overflow-y-auto hide-scrollbar"
        >
          <ul className="mt-4">
            {users.map((user) => (
              <motion.li
                key={user.id}
                className="flex items-center p-2 bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg rounded-lg shadow-lg mb-4"
                initial="hidden"
                animate="visible"
                variants={itemVariants}
                transition={{ delay: 0.2 }}
              >
                {/* User Photo */}
                <img
                  src={`./assets/img/${user.image}`}
                  alt={user.name}
                  className="w-10 h-10 object-cover rounded-full"
                />

                {/* User Details */}
                <div className="ml-4 flex flex-col flex-1">
                  {/* Username */}
                  <h3 className="text-xs mb-1">{user.name}</h3>

                  {/* Griffin Count with Coin Icon */}
                  <div className="flex items-center text-xs mb-1">
                    <img
                      src="./assets/img/coin-simple.webp"
                      alt="Griffin"
                      className="h-4 mr-1"
                    />
                    <span className="font-bold bg-gradient-to-r from-orange-400 to-yellow-600 text-transparent bg-clip-text">
                      <Formatter number={user.griffin} formatType="number" />
                    </span>
                  </div>
                </div>

                {/* Rank */}
                <span className="text-xs font-bold text-gray-600 ml-auto">Rank #{user.ranking}</span>
              </motion.li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Ranking;
