import React, { useEffect } from 'react';
import feather from 'feather-icons';

const BottomMenu = ({ handleNavigation, activeSection }) => {
  useEffect(() => {
    feather.replace();
  }, []);

  return (
    <div className="bottom-menu fixed bottom-0 w-full bg-gray-800 py-2 z-30">
      <div className="flex justify-around items-center">
        <button
          onClick={() => handleNavigation('home')}
          className={`py-1 text-gray-300 hover:text-white flex flex-col items-center ${activeSection === 'home' ? 'active' : ''}`}
        >
          <i data-feather="home" className="text-xl"></i>
          <span className="text-[10px]">Home</span>
        </button>
        <button
          onClick={() => handleNavigation('tasks')}
          className={`relative py-1 text-gray-300 hover:text-white flex flex-col items-center ${activeSection === 'tasks' ? 'active' : ''}`}
        >
          <i data-feather="check-square" className="text-xl"></i>
          <span className="text-[10px]">Tasks</span>
          <div className="absolute top-0 right-0 mt-1 mr-0 w-2.5 h-2.5 bg-red-600 rounded-full animate-bounce"></div>
        </button>
        <button
          onClick={() => handleNavigation('invite-friends')}
          className={`py-1 text-gray-300 hover:text-white flex flex-col items-center ${activeSection === 'invite-friends' ? 'active' : ''}`}
        >
          <i data-feather="user-plus" className="text-xl"></i>
          <span className="text-[10px]">Invite fren</span>
        </button>
        <button
          onClick={() => handleNavigation('airdrop')}
          className={`py-1 text-gray-300 hover:text-white flex flex-col items-center ${activeSection === 'airdrop' ? 'active' : ''}`}
        >
          <i data-feather="gift"></i>
          <span className="text-[10px]">Airdrop</span>
        </button>
      </div>
    </div>
  );
};

export default BottomMenu;
