import React from "react";
import { motion } from "framer-motion";

const ProgressBar = ({ label, width, delay }) => {
  return (
    <div className="text-center">
      <div
        className="label-progress text-white text-lg mb-1 font-bold animate-fade-in"
        style={{ animationDelay: `${delay}ms` }}
      >
        {label}
      </div>
      <div className="progress-bar-rank relative w-full h-1 mx-auto bg-gray-300">
        <motion.div
          className="progress absolute top-0 left-0 h-full"
          initial={{ width: 0 }}
          animate={{ width: `${width}%` }}
          transition={{ delay: delay / 1000, duration: 0.25, ease: "easeOut" }}  // Reduced duration for faster motion
        >
          <div className="glow-progress" />
        </motion.div>
      </div>
    </div>
  );
};

export default ProgressBar;
