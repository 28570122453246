import React, { useState, useEffect } from "react";

const getRandomValue = (min, max) => Math.random() * (max - min) + min;

const generateCoinStyles = () => {
  const coins = [];
  for (let i = 0; i < 50; i++) {
    const toX = `calc(${getRandomValue(-50, 50)}px + 24px)`;
    const toY = `${getRandomValue(2300, 1300)}%`;
    const delay = `${getRandomValue(0, 0.4)}s`;

    coins.push({
      "--coin-to-x": toX,
      "--coin-to-y": toY,
      "--coin-delay": delay,
    });
  }
  return coins;
};

const CoinAnimation = () => {
  const [coinStyles, setCoinStyles] = useState([]);

  useEffect(() => {
    setCoinStyles(generateCoinStyles());
  }, []);

  return (
    <div id="claim" className="h-full">
      {coinStyles.map((style, index) => (
        <div
          key={index}
          className="coin coin--animated"
          style={style}
        ></div>
      ))}
    </div>
  );
};

export default CoinAnimation;
