import React from "react";
import { useSpring, animated } from "@react-spring/web";
import PropTypes from "prop-types";
import Formatter from "../Formatter";

const DirectRefModal = ({ isModalOpen, closeModal }) => {
  const modalStyle = {
    width: "100%",
    height: "70vh",
    display: "block", // Always show the modal container
  };

  const modalAnimation = useSpring({
    transform: isModalOpen ? `translateY(0%)` : `translateY(100%)`,
    opacity: isModalOpen ? 1 : 0,
    config: { duration: 150 }, // Animation duration for opening
  });

  const closeModalWithAnimation = () => {
    closeModal(false);
  };

  // Example referral data structure
  const referrals = [
    {
      id: 1,
      avatar: "./assets/img/avatar/Dreamteam_1.webp",
      name: "Oketa Fred",
      amount: 25000,
    },
    {
      id: 2,
      avatar: "./assets/img/avatar/Dreamteam_2.webp",
      name: "Oketa Fred",
      amount: 25000,
    },
    {
      id: 3,
      avatar: "./assets/img/avatar/Dreamteam_3.webp",
      name: "Oketa Fred",
      amount: 25000,
    },
    {
      id: 4,
      avatar: "./assets/img/avatar/Dreamteam_4.webp",
      name: "Oketa Fred",
      amount: 25000,
    },
    {
      id: 5,
      avatar: "./assets/img/avatar/Dreamteam_2.webp",
      name: "Oketa Fred",
      amount: 25000,
    },
    {
      id: 6,
      avatar: "./assets/img/avatar/Dreamteam_5.webp",
      name: "Oketa Fred",
      amount: 25000,
    },
    {
      id: 7,
      avatar: "./assets/img/avatar/Dreamteam_6.webp",
      name: "Oketa Fred",
      amount: 25000,
    },
  ];

  return (
    <animated.div
      style={{ ...modalStyle, ...modalAnimation }}
      className="fixed bottom-0 left-0 right-0 flex items-center justify-center z-50"
    >
      <div
        className="rounded-t-3xl shadow-lg max-w-lg w-full mx-auto p-px bg-gradient-to-b from-purple-500 via-transparent to-transparent"
        style={{ height: "70vh" }}
      >
        <div className="relative rounded-t-3xl px-6 py-2 dark:bg-zinc-900 h-full overflow-hidden">
          <h2 className="text-xl font-bold mb-4 mt-6 text-center">
            Direct referrals
          </h2>
          <button
            onClick={closeModalWithAnimation}
            className="absolute top-3 right-3 p-1 text-gray-600 bg-zinc-800 rounded-full"
          >
            <i data-feather="x"></i>
          </button>
          <div className="rounded-3xl p-6 bg-zinc-800 ">
            <ul
              className="list-none overflow-auto hide-scrollbar"
              style={{ maxHeight: "calc(70vh - 220px)" }}
            >
              {referrals.map((referral) => (
                <li
                  key={referral.id}
                  className="mb-2 py-2 px-4 bg-white bg-opacity-5 backdrop-filter backdrop-blur-lg rounded-lg flex items-center justify-between"
                >
                  <div className="flex items-center">
                    <img
                      className="h-10 w-10 rounded-full"
                      src={referral.avatar}
                      alt={referral.name}
                    />
                    <div className="ml-4">
                      <h3 className="text-xs font-medium text-gray-700 dark:text-white">
                        {referral.name}
                      </h3>
                    </div>
                  </div>
                  <div className="flex items-center">
                    <img
                      src="./assets/img/coin-simple.webp"
                      alt="Griffin"
                      className="h-4 mr-2"
                    />
                    <span className="font-bold bg-gradient-to-r from-orange-400 to-yellow-600 text-transparent bg-clip-text">
                      <Formatter
                        number={parseInt(referral.amount)}
                        formatType="number"
                      />
                    </span>
                  </div>
                </li>
              ))}
            </ul>
          </div>

          <div className="absolute bottom-6 left-0 w-full px-6">
            <button
              onClick={closeModalWithAnimation}
              className="w-full py-4 text-white font-bold rounded-full bg-gradient-to-r from-purple-500 to-purple-900"
            >
              Okey!
            </button>
          </div>
        </div>
      </div>
    </animated.div>
  );
};

DirectRefModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default DirectRefModal;
