import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import api from "../Api"; // Import the api module

const Auth = ({ tg_id, tg_username, onAuthComplete }) => {
  const [hasExecuted, setHasExecuted] = useState(false); // State to track if the logic has been executed

  useEffect(() => {
    if (hasExecuted) return; // Prevent execution if it has already run

    const authenticateOrRegisterUser = async () => {
      try {
        const response = await api.post("login", {
          tg_id: tg_id, // Only send tg_id
        });
    
        console.log("Login successful:", response.data);
        onAuthComplete("success");
        // toast.success("Login successful!");
    
      } catch (error) {
        if (error.response && error.response.status === 401) {
          // Handle user not found, attempt to register (if registration is required)
          try {
            const registerResponse = await api.post("register", {  // Assuming you have a separate registration endpoint
              tg_id: tg_id,
              tg_username: tg_username, // Use tg_username here if required for registration
            });
    
            console.log("Registration successful:", registerResponse.data);
            onAuthComplete("success");
            toast.success("Registration successful!");
    
          } catch (registerError) {
            console.error("Registration failed:", registerError.response?.data || registerError.message);
            onAuthComplete("failed");
            toast.error("Registration failed. Please try again.");
          }
        } else {
          console.error("An error occurred during authentication:", error.response?.data || error.message);
          onAuthComplete("failed");
          toast.error("Authentication failed. Please try again.");
        }
      }
    };    

    authenticateOrRegisterUser();
    setHasExecuted(true); // Set the flag to true after execution
  }, [hasExecuted, tg_id, tg_username, onAuthComplete]); // Add `hasExecuted` to the dependency array

  return null; // This component doesn't render anything itself
};

export default Auth;
