import React, { useState, useEffect } from "react";
import GlowParticles from "./Glow";

const leftArrowSrc = "./assets/img/chevron-left.webp";
const rightArrowSrc = "./assets/img/chevron-right.webp";

const Slider = ({ currentIndex, setCurrentIndex }) => {
  const images = [
    { src: "./assets/img/slider/griffin-baby.webp", title: "Level 1" },
    { src: "./assets/img/slider/griffin-young.webp", title: "Level 2" },
    { src: "./assets/img/slider/griffin-strong.webp", title: "Level 3" },
  ];

  const [fade, setFade] = useState(false);

  useEffect(() => {
    if (fade) {
      const timer = setTimeout(() => {
        setFade(false);
      }, 500); // Duration of the fade effect
      return () => clearTimeout(timer);
    }
  }, [fade]);

  const goToPrevious = () => {
    setFade(true);
    setTimeout(() => {
      const newIndex = (currentIndex - 1 + images.length) % images.length;
      setCurrentIndex(newIndex);
    }, 300); // Duration of the fade effect
  };

  const goToNext = () => {
    setFade(true);
    setTimeout(() => {
      const newIndex = (currentIndex + 1) % images.length;
      setCurrentIndex(newIndex);
    }, 300); // Duration of the fade effect
  };

  let glowClass = '';
  switch (currentIndex) {
    case 1:
      glowClass = 'glow-purple-blue';
      break;
    case 2:
      glowClass = 'glow-blue-purple-pink';
      break;
    default:
      glowClass = '';
  }

  return (
    <div className="relative">
      <div className={`flex flex-col items-center transition-opacity duration-500 ${fade ? "opacity-0" : "opacity-100"}`} style={{ height: "100%", position: "relative" }}>
        <GlowParticles glowClass={glowClass} />
        <img src={images[currentIndex].src} alt={images[currentIndex].title} style={{ width: "50%", height: "auto", zIndex: 1 }} />
      </div>
      <img
        src={leftArrowSrc}
        alt="Left Arrow"
        className={`custom-arrow left-2 p-1 ${
          currentIndex === 0 ? "opacity-30 cursor-not-allowed" : "opacity-100"
        }`}
        onClick={currentIndex !== 0 ? goToPrevious : null}
      />
      <img
        src={rightArrowSrc}
        alt="Right Arrow w-full"
        className={`custom-arrow right-2 p-1 ${
          currentIndex === images.length - 1
            ? "opacity-30 cursor-not-allowed"
            : "opacity-100"
        }`}
        onClick={currentIndex !== images.length - 1 ? goToNext : null}
      />
    </div>
  );
};

export default Slider;
