import React, { useContext } from "react";
import { motion } from "framer-motion";
import Formatter from "../components/Formatter";
import CoinAnimation from "../components/CoinAnimation";
import { TimerContext } from '../contexts/TimerContext';

const Home = ({ handleNavigation, showClaim, setShowClaim }) => {
  const {
    maxTime,
    timeElapsed,
    userScore,
    isScaling,
    claimCounter,
    handleClaimClick,
  } = useContext(TimerContext);

  const initialPPH = 1000; // Profit per hour

  const getPercentage = () => {
    return ((maxTime - timeElapsed) / maxTime) * 100;
  };

  const elapsedHours = (maxTime - timeElapsed) / 3600; // Calculate elapsed time in hours
  const scoreToAdd = elapsedHours * initialPPH; // Calculate score to add based on PPH and elapsed hours

  return (
    <div className="flex flex-col items-center justify-between min-h-screen">
      <div className="text-center max-w-md w-full flex-grow flex flex-col justify-between">
        <div className="flex justify-between space-x-4">
          <div className="inline-block flex items-center mb-4 py-0 px-2 rounded-2xl overflow-hidden shadow-lg bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg">
            <img
              className="inline-block w-5 pr-1"
              src="./assets/img/user.webp"
              alt="User"
            />
            <span className="inline-block text-xs">jahanjavid</span>
          </div>
          <button
            onClick={() => handleNavigation("activity")}
            className="inline-block flex items-center mb-4 p-2 rounded-2xl overflow-hidden shadow-lg bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg"
          >
            <img
              className="inline-block w-5 pr-1"
              src="./assets/img/cup.webp"
              alt="Boost"
            />
            <span className="inline-block text-xs">Activity</span>
          </button>
        </div>
        <div className="mt-3 mb-3 text-xs">
          <p>Hey there! come back every 4 hours</p>
          <p>and claim griffin rewards</p>
        </div>
        <div className="flex justify-center">
          <motion.h2
            animate={{
              scale: isScaling ? [1, 1.3, 1] : 1, // Add scaling animation
            }}
            transition={{ duration: 0.4 }}
            className="flex items-center text-2xl font-bold mt-2 mb-1 bg-gradient-to-r from-orange-600 to-yellow-600 text-transparent bg-clip-text"
          >
            <motion.img
              src="./assets/img/coin-simple.webp"
              alt="Griffin"
              className="h-7 mr-2 target-coin"
              animate={{
                scale: isScaling ? [1, 1.05, 1] : 1, // Add scaling animation
              }}
              transition={{ duration: 0.4 }}
            />
            <Formatter number={userScore.toFixed(0)} formatType="separator" />
          </motion.h2>
        </div>
        <div className="flex-grow flex items-center justify-center">
          <div className="relative bottom-10">
            <div className="img-wrapper">
              <img src="./assets/img/griffin-strong.webp" alt="Griffin" />
            </div>
            <div className="inner"></div>
          </div>
        </div>
      </div>
      <div className="w-full py-4 flex flex-col items-center relative bottom-90">
        <div className="flex justify-between space-x-4 mb-8 w-full max-w-md">
          <motion.div
            initial={{ x: 10 }}
            animate={{ x: [0, 10, -10, 5, 0] }}
            transition={{ duration: 0.5, ease: "easeInOut" }}
            className="flex items-center py-0 rounded-2xl overflow-hidden"
          >
            <img
              className="inline-block w-10 pr-1"
              src="./assets/img/pph.webp"
              alt="User"
            />
            <span className="text-base font-bold"><Formatter number={initialPPH} formatType="number" /></span>
            <span className="text-[9px] opacity-30 ml-1">
              / Profit per hour
            </span>
          </motion.div>
          <motion.button
            onClick={() => handleNavigation("boost")}
            initial={{ x: 10 }}
            animate={{ x: [0, -10, 10, -5, 0] }}
            transition={{ duration: 0.5, ease: "easeInOut" }}
            className="flex items-center rounded-2xl overflow-hidden"
          >
            <img
              className="inline-block w-10"
              src="./assets/img/boost.webp"
              alt="Boost"
            />
            <span className="text-xs">Boost</span>
          </motion.button>
        </div>
        <motion.button
          type="button"
          className="text-white bg-gradient-to-br from-pink-500 to-orange-400 font-medium rounded-full w-80 text-sm px-5 py-2.5 text-center mb-4 flex items-center justify-center space-x-2 relative"
          whileHover={{ scale: 1.05 }}
          onClick={() => {
            handleClaimClick();
            setShowClaim(true); // Show the CoinAnimation on button click
          }}
        >
          <div className="relative w-6 h-6 inline-block">
            <img
              src="./assets/img/timer-frame.png"
              alt="Timer Frame"
              className="absolute inset-0 w-full h-full"
            />
            <div className="absolute inset-0 flex items-center justify-center">
              <div
                className="absolute top-3.2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-4 h-4 rounded-full"
                style={{
                  background: `conic-gradient(white ${getPercentage()}%, rgba(0, 0, 0, 0.2) ${getPercentage()}%)`,
                }}
              ></div>
            </div>
          </div>

          <span>Claim your griffin</span>
          <span className="inline-block text-xs ml-2 font-bold font-mono">
            ${scoreToAdd.toFixed(2)}
          </span>
        </motion.button>
        {showClaim && <CoinAnimation key={claimCounter} />}
      </div>
    </div>
  );
};

export default Home;
