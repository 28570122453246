import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Formatter from "./Formatter";
import Loading from "../components/Loading"; // Import your custom Loading component
import api from "../Api"; // Import the Axios instance

const Plans = () => {
  const [boosts, setBoosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingPlanId, setLoadingPlanId] = useState(null);

  useEffect(() => {
    // Fetch the boost plans using the Axios instance
    api
      .get("/boosts")
      .then((response) => {
        console.log("API Response:", response.data);
        setBoosts(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        toast.error("Failed to load plans. Please try again later.");
        setLoading(false);
      });
  }, []);

  const handleBuyClick = (planId) => {
    setLoadingPlanId(planId);

    const purchasePromise = new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(); // Simulate successful purchase
      }, 2000); // Simulated delay
    });

    toast
      .promise(purchasePromise, {
        pending: "Processing your purchase...",
        success: "Purchase successful!",
        error: "Something went wrong. Please try again later.",
        position: "top-center",
        closeButton: false,
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        theme: "dark",
      })
      .then(() => {
        setLoadingPlanId(null);
      })
      .catch(() => {
        setLoadingPlanId(null);
      });
  };

  if (loading) {
    return <Loading />; // Show loading component while fetching data
  }

  return (
    <div
      className="overflow-y-scroll hide-scrollbar"
      style={{ maxHeight: "calc(100vh - 150px)" }}
    >
      <div className="grid grid-cols-2 gap-4">
        {boosts.map((plan, index) => (
          <motion.div
            key={plan.id}
            className={`p-4 bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg rounded-lg shadow-lg flex flex-col items-center ${
              index === 0 ? "border-2 border-purple-500" : ""
            }`}
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3, delay: index * 0.1 }}
          >
            <img
              src={plan.icon} // Assuming the image URL is included in your API response
              alt={plan.pph}
              className="w-20 h-20 object-cover mb-2"
            />
            <h3 className="text-sm font-bold mb-2">
              <span className="bg-gradient-to-r from-orange-600 to-yellow-600 text-transparent bg-clip-text">
                +<Formatter number={plan.pph} formatType="separator" />
              </span>
              <span className="text-xs opacity-30"> / PPH</span>
            </h3>
            <p className="text-xs text-center mb-2">{plan.description}</p>
            {plan.price !== undefined ? (
              <div className="text-xl font-bold mb-4 flex items-center justify-center">
                <img
                  src="./assets/img/coin-simple.webp"
                  alt="Griffin"
                  className="h-5 mr-2 inline-block"
                />
                <Formatter
                  number={plan.price.toString().replace(/,/g, "")}
                  formatType="number"
                />
              </div>
            ) : (
              <p className="text-xl font-bold mb-4">Price not available</p>
            )}
            <button
              className={`w-full py-2 text-white font-bold text-sm rounded-full shadow-lg flex items-center justify-center ${
                index === 0 || loadingPlanId === plan.id
                  ? "bg-orange-400 cursor-not-allowed"
                  : "bg-gradient-to-r from-purple-500 to-blue-500"
              }`}
              disabled={index === 0 || loadingPlanId === plan.id}
              onClick={() => handleBuyClick(plan.id)}
            >
              {loadingPlanId === plan.id ? (
                <Loading />
              ) : index === 0 ? (
                "You own this"
              ) : (
                "Buy"
              )}
            </button>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default Plans;
