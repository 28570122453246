import React from "react";

const GlowParticles = ({ glowClass }) => {
  return (
    <div className={`glow-one ${glowClass}`}>
      <div className="particles-one">
        <div className="rotate-one">
          <div className="angle-one">
            <div className="size-one">
              <div className="position-one">
                <div className="pulse-one">
                  <div className="particle-one"></div>
                </div>
              </div>
            </div>
          </div>

          <div className="angle-one">
            <div className="size-one">
              <div className="position-one">
                <div className="pulse-one">
                  <div className="particle-one"></div>
                </div>
              </div>
            </div>
          </div>

          <div className="angle-one">
            <div className="size-one">
              <div className="position-one">
                <div className="pulse-one">
                  <div className="particle-one"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GlowParticles;
